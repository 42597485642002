import React from 'react';
import Container from '../components/container';
import Layout from '../containers/layout';
import SEO from '../components/seo';

const Roanne = () => {
  return (
    <Layout>
      <SEO title='Roanne Adams - Founder and Chief Creative Director' />
      <Container>
        <h1 hidden>Roanne</h1>
        <div className='roanne'>
          <div className='col left-content'>
            <div className='image-wrapper'>
              <img
                className='profile-image'
                src='https://cdn.sanity.io/images/7smmycik/production/484437ed54907bb94c498c5cf2a78d08fa153dd7-2000x3000.jpg?q=85&dpr=0.75'
              />
            </div>
          </div>

          <div className='col right-content'>
            <div className='base-content'>
              <h3 className='founder'>ROANNE ADAMS</h3>
              <span className='role'>Founder and Chief Creative Director</span>
              <div>
                <p>
                  Roanne founded RoAndCo in 2006 to create and collaborate with the people who
                  inspire her. The studio quickly gained recognition for Roanne’s intrepid ability
                  to build successful, scalable brands for entrepreneurs from the ground up before
                  expanding to service larger lifestyle clients such as Google and Estée Lauder.
                </p>
                <p>
                  She still loves connecting directly with founders, helping them envision, create,
                  and launch their brands. For Roanne, creativity comes from within and thrives
                  through connection and collaboration. Idealistic and optimistic, creative and
                  strategic, empathetic and organized, as Roanne evolves, so does her practice. She
                  finds purpose as Chief Creative Director, inspiring people to expand their
                  consciousness and tap into their personal well of creativity.
                </p>
                <p>
                  Most in her flow when helping others find theirs, Roanne can sense what clients
                  want, can see what creatives need, and help both rise to their full potential. As
                  a female business owner — and being that only 3% of Creative Directors in the
                  industry are female — Roanne has always made it a priority to nurture
                  opportunities for women and elevate their seats at the table, from her work with
                  non-profit initiatives like the Women’s March, Together We Rise, and Women Under
                  the Influence to her honor as a UN Champion for Change in advocating for equal pay
                  and equal say. Her vision to bring creative spirits together culminated in the
                  founding of Romance Journal, a biannual publication devoted to raising collective
                  consciousness through a female lens. She has extended its roots with the launch of
                  A State of Feeling, an insightful conversation series that reveals the truths
                  inherent in personal and collective states and examines leadership through
                  creativity and business (see about statement below). Throughout her career, she
                  has won numerous awards including ADC’s Young Guns 9, has served on the Board of
                  Directors for AIGA’s New York chapter, and has been named by T Magazine as one of
                  New York’s most outstanding design professionals.
                </p>
                <p>
                  With an eye for style and a skill for capturing a brand’s essence, Roanne is
                  trusted and respected by her clients and collaborators alike. Always evolving, her
                  creative practice unfolds with her journey to be a creative conduit for good.
                  Energized by the visions of all she works with, she seeks to tap into their truth
                  and co-create a better future.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Roanne;
